import { Container } from "react-bootstrap";
import LayoutPage from "../components/LayoutPage";
import { Link } from "gatsby";
import RLink from "../components/RLink";
import React from "react";
import Seo from "../components/Seo";
import Webterm from "../components/Webterm";
import staticData from "../components/staticData";

export default function Terminal() {
  return (
    <LayoutPage center>
      <Seo title="WebhookDB Terminal" />
      <Container className="pt-2 px-2" fluid>
        <div className="m-3">
          <p className="mb-1">
            This is a fully functional terminal for WebhookDB. When you close your tab,
            your session is removed. If you&rsquo;d prefer something local, you should{" "}
            <Link to="/download">download the CLI</Link>.
          </p>
          <p className="mb-2">
            Check out <RLink to={staticData.docs()}>how to get started</RLink>, or{" "}
            <RLink href="/contact">contact us</RLink> or email{" "}
            <a href="mailto:hello@webhookdb.com">hello@webhookdb.com</a> if you run into
            any problems.
          </p>
          <Webterm width="100%" style={{ height: "80vh" }} autofocus />
        </div>
      </Container>
    </LayoutPage>
  );
}
